import classNames from 'classnames'
import cn from 'classnames'
import VerifiedBadgeIcon from 'shared/icons/VerifiedBadgeIcon'
import { getEllipsisText } from 'utils/string'

type Props = {
  fullName: string | null // some users have fullName is null
  isVerified: boolean
  size: 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl' | '2xl'
  customClass?: string
  maxNameLength?: number // pass a negative number to disable ellipsis
}

const UserNameWithVerifiedMark = (props: Props) => {
  const { fullName, isVerified, size, customClass, maxNameLength = 12, ...otherProps } = props
  const name = getEllipsisText(fullName || '', maxNameLength)
  return (
    <span
      className={cn(
        'font-medium ',
        size === 'xs' && 'text-[10px]',
        size === 'sm' && 'text-[14px]',
        size === 'base' && 'text-[16px]',
        size === 'md' && 'text-[20px]',
        size === 'lg' && 'text-[24px]',
        size === 'xl' && 'text-[32px]',
        size === '2xl' && 'text-[40px]',
        customClass,
      )}
      {...otherProps}
    >
      <span>
        {name.slice(0, name.length - 1)}
        <span className="relative">
          {name.slice(name.length - 1, name.length)}
          {isVerified && (
            <span
              className={classNames(
                'absolute right-0 top-0 ml-[0.2em] text-inherit',
                (size === 'lg' || size === 'xl' || size === '2xl') && '-translate-y-[15%] translate-x-[110%]',
                size === 'md' && '-translate-y-[15%] translate-x-[120%]',
                size === 'base' && '-translate-y-[15%] translate-x-[130%]',
                size === 'sm' && '-translate-y-[15%] translate-x-[140%]',
                size === 'xs' && '-translate-y-[25%] translate-x-[140%]',
              )}
            >
              <VerifiedBadgeIcon className="inline !h-6 w-6" />
            </span>
          )}
        </span>
        {isVerified && <span className="pr-3 lg:pr-5" />}
      </span>
    </span>
  )
}

export default UserNameWithVerifiedMark
