import { QRCodeSVG } from 'qrcode.react'

import { createDeepLinkToPagePath } from 'utils/common'
import RoundedImage from 'shared/components/RoundedImage'
import CloseIcon from 'shared/icons/CloseIcon'
import ModalWrapper from 'shared/components/modal/ModalWrapper'
import UserNameWithVerifiedMark from 'shared/components/users/UserNameWithVerifiedMark'

type QRModalProps = {
  onClose: () => void
  userPhoto?: string
  fullName: string
  username: string
  open: boolean
  isVerified: boolean
}

const QRModal = (props: QRModalProps) => {
  const { onClose, userPhoto, fullName, username, open, isVerified } = props
  const deeplink = createDeepLinkToPagePath(`/u/${username}`)

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      panelWrapperClass="flex h-full w-full items-center justify-center"
      blurBackdrop
      panelClass="relative flex !h-[493px] !w-[335px] flex-col items-center justify-center gap-y-5 rounded-[30px] bg-white"
    >
      <button className="absolute right-4 top-4 h-8 w-8 text-lg" onClick={onClose}>
        <CloseIcon />
      </button>
      <div className="h-[90px] w-[90px] rounded-full shadow-cohart-sm">
        <RoundedImage src={userPhoto || '/icons/default-ava.svg'} shadowed bordered alt="user avatar" />
      </div>
      <div className="px-6 text-center">
        <UserNameWithVerifiedMark fullName={fullName} isVerified={isVerified} size="md" maxNameLength={-1} />
        <div className="text-sm">
          www.cohart.com/u/<span>{username}</span>
        </div>
      </div>
      <QRCodeSVG
        value={deeplink}
        size={200}
        level="L"
        imageSettings={{ src: '/images/logo.webp', height: 26, width: 50, excavate: true }}
      />
    </ModalWrapper>
  )
}

export default QRModal
